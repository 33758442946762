// TODO: Use shared-utils

export const screenSizes = {
  'xs': 375,
  'xsl': 640,
  'sm': 744,
  'md': 950,
  'lg': 1128,
  'xl': 1440,
  '2xl': 1840,
};

export const LOCAL_STORAGE = {
  COUPON_SHARE_BANNER_CLOSED: 'COUPON_SHARE_BANNER_CLOSED',
};

export const MERCHANT_INFO_KEYS = {
  PHONE_NUMBER: 'phoneNumber',
  CUSTOMER_URL: 'customerUrl',
  SHIPPING_URL: 'shippingInfo',
  LOCATOR_URL: 'locatorUrl',
};

export const BANNER_VERSION = 'ios-v1';

export const EXT_MOBILE_SAFARI_TRACKING_URL
  = 'https://apps.apple.com/us/app/simplycodes/id1538885494?utm_source=sc+web&utm_medium=smart+banner+desktop&utm_campaign=sc+ext+ios+cross-promo';

// Default max pagination results
export const MAX_RESULTS_DEFAULT = 20;

export const MAX_TRENDING_DEFAULTS = 16;

// Time-related constants
export const ONE_YEAR_IN_MILLISECONDS = 60 * 60 * 24 * 365;

// Cookie names
export const COOKIES = {
  REFERRAL: 'sc_referral',
  KARMA_OPT_IN: 'sc_karma_beta_opt_in',
};

export const MAX_REFERRALS_PER_USER = 5;

// for use on the openSearch animation
export const OPEN_SEARCH_DURATION = 300;

export const AUTH_CLIENT_ID = 1345;
export const AUTH_USER_SCOPES = ['v1:registereduser'];
export const AUTH_GUEST_SCOPES = ['v1:guest'];

/**
 * WINDOW_MESSAGE_EVENT_TYPES for window.postMessage && window.onMessage
 */
export const WINDOW_MESSAGE_EVENT_TYPES = {
  LOGIN: 'SimplyCodesLogin',
  LOGOUT: 'SimplyCodesLogout',
  EXT_LOGIN: 'ExtensionLogin',
  EXT_LOGOUT: 'ExtensionLogout',
  NAVIGATE: 'SimplyCodesNavigate',
};
